<template>
  <div class="cnt-alert-info">
    <ion-icon class="info-icon mt-3" :icon="informationCircleOutline" />
    <div class="px-3 mb-3">
      <ion-label class="fw-500 fs-3">{{ message }}</ion-label>
    </div>
    <div class="grey-line"></div>
    <ion-button class="fw-bold" expand="full" fill="clear" color="primary" @click="$emit('close-modal')">{{
      buttonText
    }}</ion-button>
  </div>
</template>
<script>
import { informationCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {
      informationCircleOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.info-icon {
  height: 52px;
  width: 52px;
  color: #00676a;
  margin-bottom: 16px;
}
.cnt-alert-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}
.grey-line {
  height: 1px;
  width: 100%;
  color: rgba(224, 224, 224, 1);
  background: rgba(224, 224, 224, 1);
}
</style>
